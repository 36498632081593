import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import React from 'react';

import App from 'application/app';
import { addApolloState } from 'application/graphql/apollo-client';
import GeneralLayout from 'application/layouts/general-layout';

import type { AppPage } from './_app';

const IndexPage = dynamic(() => import('features/main/pages/index-page'));

const IndexAppPage: AppPage = () => {
  return <IndexPage />;
};

const IndexAppPageWithTranslation = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { t } = useTranslation(['common', 'seo']);

  return (
    <GeneralLayout
      title={t('common:labels:home')}
      description={t('seo:main:description')}
      imgPreview="/android-chrome-512x512.png" // TODO
      footer={false}
    >
      {children}
    </GeneralLayout>
  );
};

IndexAppPage.getLayout = (page: React.ReactElement) => (
  <IndexAppPageWithTranslation>{page}</IndexAppPageWithTranslation>
);

export const getServerSideProps: GetServerSideProps = async ({ req, res }) => {
  const app = await App.init({ headers: req.headers, res });

  return addApolloState(app.apolloClient, {
    props: {
      appConfig: app.config,
      ...(await app.getServerSideTranslations()),
    },
  });
};

export default IndexAppPage;
